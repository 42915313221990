require('bootstrap');
require('bootstrap/dist/css/bootstrap.css');
require('@fortawesome/fontawesome-free/css/all.min.css');

require('../css/app.scss');

const $ = require('jquery');
window.jQuery = $;
window.Popper = require('popper.js');

$(document).ready(function(){
    //remove cookie message bar
    $("#close").click(function(){
        $(".cookie-notification").remove();

        $.ajax({url: "/remove-cookie-message/"});

    });

    //$('[data-toggle="tooltip"]').tooltip()
    $('body').tooltip({
        selector: '[data-toggle="tooltip"]'
    });

});
